import React from "react";
import { Form, Input, Upload } from "antd";
import UploadUtils from "../../utilities/uploadUtils";
import SortDraggableList from "../SortDraggableList";

const SortableTrustOurWork = ({ data, setData, form }) => {
  const workItems =
    data?.our_works?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const renderWorkContent = (field) => (
    <div>
      <Form.Item
        label="Work Name"
        name={[field.name, "work_name"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Work Image"
        name={[field.name, "work_image"]}
        {...UploadUtils.formItemProps}
        wrapperCol={{ span: 24 }}
      >
        <Upload {...UploadUtils.buttonPreviewProps}>
          <UploadUtils.CardContent />
        </Upload>
      </Form.Item>
    </div>
  );

  return (
    <SortDraggableList
      items={workItems}
      renderContent={renderWorkContent}
      setData={setData}
      form={form}
      formField="our_works"
      addContent={false}
      showRemoveOption={false}
      cardTitle="Our Work"
    />
  );
};

export default SortableTrustOurWork;
