import React from "react";
import { Form, Input, Select } from "antd";
import SortDraggableList from "../SortDraggableList";

const FooterImportantLinksSort = ({ data, setData, form }) => {
  const importantLinkItems =
    data?.important_links?.map((item, idx) => ({
      id: idx.toString(),
      ...item,
    })) || [];

  const renderLinkContent = (field, index) => (
    <div style={{ width: "270px" }}>
      <Form.Item
        label="Link Name"
        name={[field.name, "link_name"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Select Link Type" name={[field.name, "link_type"]}>
        <Select
          options={[
            {
              value: "internal",
              label: "Internal",
            },
            {
              value: "external",
              label: "External",
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.important_links?.[index]?.link_type !==
          currentValues.important_links?.[index]?.link_type
        }
        noStyle
      >
        {({ getFieldValue }) => {
          let type = getFieldValue(["important_links", index, "link_type"]);

          return (
            <>
              {type === "external" && (
                <Form.Item
                  label="Link Url"
                  name={[field.name, "redirect_path"]}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input addonBefore="https://repair-customer.zen5.io" />
                </Form.Item>
              )}
              {type === "internal" && (
                <>
                  <Form.Item
                    label="Link Url"
                    name={[field.name, "redirect_path"]}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input addonBefore="https://repair-customer.zen5.io" />
                  </Form.Item>
                </>
              )}
            </>
          );
        }}
      </Form.Item>
    </div>
  );

  return (
    <SortDraggableList
      items={importantLinkItems}
      renderContent={renderLinkContent}
      setData={setData}
      form={form}
      formField="important_links"
      addContent={true}
      showRemoveOption={true}
      cardTitle="Important Link"
    />
  );
};

export default FooterImportantLinksSort;
