import React from "react";
import { Form, Input, Upload } from "antd";
import UploadUtils from "../../utilities/uploadUtils";
import SortDraggableList from "../SortDraggableList";
import ReactQuill from "react-quill";

const WhyYouChooseSort = ({ data, setData, form }) => {
  const whyYouChooseItems =
    data?.why_you_choose?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const renderWhyYouChooseContent = (field) => (
    <div style={{ width: "250px" }}>
      <Form.Item
        label="Name"
        name={[field.name, "name"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name={[field.name, "description"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <ReactQuill theme="snow" rows={4} />
      </Form.Item>
      <Form.Item
        label="Image"
        name={[field.name, "image"]}
        {...UploadUtils.formItemProps}
        wrapperCol={{ span: 24 }}
      >
        <Upload {...UploadUtils.buttonPreviewProps}>
          <UploadUtils.CardContent />
        </Upload>
      </Form.Item>
    </div>
  );

  return (
    <SortDraggableList
      items={whyYouChooseItems}
      renderContent={renderWhyYouChooseContent}
      setData={setData}
      form={form}
      formField="why_you_choose"
      addContent={false}
      showRemoveOption={false}
      cardTitle="Why you choose"
    />
  );
};

export default WhyYouChooseSort;
