import React from "react";
import { Form, Input, InputNumber, Upload } from "antd";
import UploadUtils from "../../utilities/uploadUtils";
import SortDraggableList from "../SortDraggableList";
import TextArea from "antd/es/input/TextArea";

const SalesmanSort = ({ data, setData, form }) => {
  const items =
    data?.salesman?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const renderContent = (field) => (
    <div style={{ width: "270px" }}>
      {/* <Form.Item
        label="Salesman Date"
        name={[field.name, "salesman_date"]}
        wrapperCol={{ span: 24 }}
        rules={[
        {
            required: true,
            message: "This field is required",
        },
        ]}
        >
            <DatePicker
            format={(d) => dayjs(d).format("MMM D, YYYY")}
            disabledDate={(date) => !date.isBefore(dayjs())}
            />
        </Form.Item> */}
      <Form.Item
        label="Salesman Image"
        name={[field.name, "salesman_image"]}
        {...UploadUtils.formItemProps}
        wrapperCol={{ span: 24 }}
      >
        <Upload {...UploadUtils.buttonPreviewProps}>
          <UploadUtils.CardContent />
        </Upload>
      </Form.Item>
      <Form.Item
        label="Salesman Name"
        name={[field.name, "salesman_name"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Salesman Info"
        name={[field.name, "salesman_info"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Salesman Rating"
        name={[field.name, "salesman_rating"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <InputNumber min={0} max={5} />
      </Form.Item>
      <Form.Item
        label="Salesman Review"
        name={[field.name, "salesman_review"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <TextArea />
      </Form.Item>
    </div>
  );

  return (
    <SortDraggableList
      items={items}
      renderContent={renderContent}
      setData={setData}
      form={form}
      formField="salesman"
      addContent={true}
      showRemoveOption={true}
      cardTitle="Salesman"
    />
  );
};

export default SalesmanSort;
