import React from "react";
import { Form, Upload } from "antd";
import UploadUtils from "../../utilities/uploadUtils";
import SortDraggableList from "../SortDraggableList";

const SortableAffiliation = ({ data, setData, form }) => {
  const affiliationItems =
    data?.affiliations?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const renderAffiliationContent = (field) => (
    <Form.Item
      label="Affiliation Image"
      name={[field.name, "affiliation_image"]}
      {...UploadUtils.formItemProps}
    >
      <Upload {...UploadUtils.buttonPreviewProps}>
        <UploadUtils.CardContent />
      </Upload>
    </Form.Item>
  );

  return (
    <SortDraggableList
      items={affiliationItems}
      renderContent={renderAffiliationContent}
      setData={setData}
      form={form}
      formField="affiliations"
      addContent={true}
      showRemoveOption={true}
      cardTitle="Affiliation"
    />
  );
};

export default SortableAffiliation;
