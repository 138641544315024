import React from "react";
import { Form, Upload } from "antd";
import UploadUtils from "../../utilities/uploadUtils";
import SortDraggableList from "../SortDraggableList";

const FooterAcceptedPaymentSort = ({ data, setData, form }) => {
  const acceptedPaymentItems =
    data?.accepted_payments?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const renderPaymentContent = (field) => (
    <Form.Item
      label="Payment Image"
      name={[field.name, "payment_image"]}
      {...UploadUtils.formItemProps}
      wrapperCol={{ span: 24 }}
    >
      <Upload {...UploadUtils.buttonPreviewProps}>
        <UploadUtils.CardContent />
      </Upload>
    </Form.Item>
  );

  return (
    <SortDraggableList
      items={acceptedPaymentItems}
      renderContent={renderPaymentContent}
      setData={setData}
      form={form}
      formField="accepted_payments"
      addContent={true}
      showRemoveOption={true}
      cardTitle="Payment Image"
    />
  );
};

export default FooterAcceptedPaymentSort;
