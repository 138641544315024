import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Col,
  Row,
  Input,
  Flex,
  Button,
  Upload,
  Table,
  Divider,
  Modal,
  Descriptions,
  Select,
  Tag,
} from "antd";
import PageHeader from "../../../components/pageHeader";
import Dragger from "antd/es/upload/Dragger";
import UploadUtils from "../../../utilities/uploadUtils";
import ReactQuill from "react-quill";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  handleExcelExport,
  handleExcelFileUpload,
} from "../../../utilities/handleExcelOperations";

const VerifiedInstallerForm = ({ mode, formFor, editLayout }) => {
  const [data, setData] = useState(formFor?.en?.data);
  const [locations, setLocations] = useState(data?.verified_location || []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [modalDataIndex, setModalDataIndex] = useState(null);
  const [importedData, setImportedData] = useState([]);
  const [current, setCurrent] = useState(1);

  const handleOk = () => {
    setLocations(importedData);
  };

  const handleSubmit = (values) => {
    values.verified_location = locations;
    // console.log(values);
    try {
      // values.banner1_image = UploadUtils.processUploadImage(
      //   values.banner1_image
      // );
      // values.banner2_image = UploadUtils.processUploadImage(
      //   values.banner2_image
      // );
      // // values.banner3_image = UploadUtils.processUploadImage(
      // //   values.banner3_image
      // // );

      // values.banner3_images = values.banner3_images.map((i) => ({
      //   image: UploadUtils.processUploadImage(i.image),
      // }));

      // values.body_banner_image = UploadUtils.processUploadImage(
      //   values.body_banner_image
      // );
      // values.why_you_choose = values.why_you_choose.map((i) => ({
      //   name: i.name,
      //   image: UploadUtils.processUploadImage(i.image),
      //   description: i.description,
      // }));

      // values.feature_image = UploadUtils.processUploadImage(
      //   values.feature_image
      // );
      // values.affiliations = values.affiliations.map((i) => ({
      //   affiliation_image: UploadUtils.processUploadImage(i.affiliation_image),
      // }));
      // values.tennessee = UploadUtils.processUploadImage(values.tennessee);
      // values.florida = UploadUtils.processUploadImage(values.florida);
      // values.texas = UploadUtils.processUploadImage(values.texas);

      const seo_setting = [
        {
          id: formFor.seo_setting?.[0]?.id,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          feature_image: values.feature_image,
        },
      ];
      setData(data);
      editLayout(values, "Verified Installer", seo_setting);
    } catch (error) {
      console.log(error);
    }
  };

  const openModal = (mode, data, index) => {
    setModalDataIndex(index);
    setModalMode(mode);
    if (mode === "Edit" || mode === "View") {
      setModalData(data);
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalData(null);
    setModalMode(null);
    setModalDataIndex(null);
    setIsModalOpen(false);
  };

  const handleAddLocation = (values) => {
    const newLocation = {
      ...values,
      key: locations.length ? locations[locations.length - 1].key + 1 : 0,
    };
    setLocations([...locations, newLocation]);
    closeModal();
  };

  const handleEditLocation = (values) => {
    const updatedLocations = locations.map((loc, index) =>
      index === modalDataIndex ? { ...loc, ...values } : loc
    );
    setLocations(updatedLocations);
    closeModal();
  };

  const handleDeleteLocation = (index) => {
    const updatedLocations = locations.filter((_, idx) => idx !== index);
    setLocations(updatedLocations);
  };

  const handleFormSubmit = (values) => {
    if (modalMode === "Add") {
      handleAddLocation(values);
    } else if (modalMode === "Edit") {
      handleEditLocation(values);
    }
  };

  const handleExportClick = () => {
    const verifiedLocations = data?.verified_location || [];
    const allStatusesPresent = verifiedLocations.every(
      (entry) => entry.status && entry.status.trim() !== ""
    );
    if (!allStatusesPresent) {
      alert(
        "Some entries are missing the status field. Please check your data."
      );
      return;
    }
    handleExcelExport(verifiedLocations);
  };

  return (
    <>
      <Form
        disabled={mode === "View"}
        layout="vertical"
        initialValues={data}
        onFinish={(values) => handleSubmit(values)}
      >
        <PageHeader title={`${formFor?.title.replace("-", " ")} Page`} />
        <Row gutter={16} className="mt30">
          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Verified Section">
              <Form.Item
                label="Verified Title"
                name={["verified_installer", "verified_installer_title"]}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Verified Description"
                name={["verified_installer", "verified_installer_description"]}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <ReactQuill theme="snow" rows={4} />
              </Form.Item>
            </Card>
          </Col>
          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="With Verified Installation Section">
              <Form.Item
                label="Verified Installation Title"
                name={[
                  "with_verified_installation",
                  "with_verified_installation_title",
                ]}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Card size="small" title="With Verified Installation Point">
                <Form.List
                  name={[
                    "with_verified_installation",
                    "with_verified_installation_point",
                  ]}
                >
                  {(fields, { add, remove }) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {fields.map((field, index) => (
                        <Flex key={field.key} gap={30}>
                          <Form.Item
                            label={`Verified Installation Point ${index + 1}`}
                            name={[field.name, "verified_installation_point"]}
                            rules={[
                              {
                                required: true,
                                message: "This field is required",
                              },
                            ]}
                            style={{ flexGrow: 1 }}
                          >
                            <Input />
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Flex>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Verified Installation Point
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </Form.List>
              </Card>
            </Card>
          </Col>

          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Verified Installer Location Section">
              <Form.Item
                label="Location Title"
                name="location_title"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Card size="small" style={{ marginBottom: "10px" }}>
                <Flex justify="space-between" align="center">
                  <div>
                    <input
                      type="file"
                      accept=".xlsx, .xls"
                      onChange={(event) =>
                        handleExcelFileUpload(event, setImportedData)
                      }
                    />
                  </div>
                  <Flex justify="end" align="center" gap={8}>
                    <Button
                      size="small"
                      icon={<i className="ri-download-cloud-2-line"></i>}
                      disabled={importedData?.length === 0}
                      onClick={() => {
                        Modal.confirm({
                          title: "Are you sure?",
                          content: "This will overwrite your existing data.",
                          onOk: handleOk,
                          footer: (_, { OkBtn, CancelBtn }) => (
                            <>
                              <CancelBtn />
                              <OkBtn />
                            </>
                          ),
                        });
                      }}
                    >
                      Import data from chosen file
                    </Button>
                    <Button
                      size="small"
                      icon={<i class="ri-upload-2-line"></i>}
                      onClick={handleExportClick}
                    >
                      Export
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => openModal("Add", [], null)}
                    >
                      Add
                    </Button>
                  </Flex>
                </Flex>
              </Card>
              <Table
                columns={[
                  {
                    title: "Location Title",
                    dataIndex: "location_title",
                    render: (value, record, index) => {
                      return (
                        <div>
                          <span>{value}</span>
                          <div style={{ display: "none" }}>
                            {/* <Form.Item
                              label="Location Title"
                              name={[
                                "verified_location",
                                index,
                                "location_title",
                              ]}
                              wrapperCol={{ span: 24 }}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                              shouldUpdate
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Status"
                              name={["verified_location", index, "status"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Status is required",
                                },
                              ]}
                              shouldUpdate
                            >
                              <Select
                                style={{
                                  width: "100%",
                                }}
                                options={[
                                  {
                                    value: "ACTIVE",
                                    label: "Active",
                                  },
                                  {
                                    value: "INACTIVE",
                                    label: "Inactive",
                                  },
                                ]}
                              />
                            </Form.Item>
                            <Form.Item
                              label="Location Address"
                              name={[
                                "verified_location",
                                index,
                                "location_address",
                              ]}
                              wrapperCol={{ span: 24 }}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                              shouldUpdate
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Phone Number"
                              name={[
                                "verified_location",
                                index,
                                "phone_number",
                              ]}
                              wrapperCol={{ span: 24 }}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                              shouldUpdate
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Email"
                              name={["verified_location", index, "email"]}
                              wrapperCol={{ span: 24 }}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                              shouldUpdate
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Website Url"
                              name={["verified_location", index, "website_url"]}
                              wrapperCol={{ span: 24 }}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                              shouldUpdate
                            >
                              <Input />
                            </Form.Item> */}
                          </div>
                        </div>
                      );
                    },
                  },
                  {
                    title: "Phone Number",
                    dataIndex: "phone_number",
                  },
                  {
                    title: "Status",
                    dataIndex: "status",
                    render: (value, record, index) => {
                      return (
                        <Tag
                          color={
                            (value ?? "").toLowerCase() === "active"
                              ? "green"
                              : (value ?? "").toLowerCase() === "inactive"
                              ? "red"
                              : "default"
                          }
                        >
                          {value}
                        </Tag>
                      );
                    },
                  },
                  {
                    title: "Action",
                    dataIndex: "action",
                    align: "center",
                    render: (value, record, index) => {
                      return (
                        <Flex align="center" justify="center">
                          <Button
                            type="text"
                            onClick={() => openModal("View", record, index)}
                          >
                            View
                          </Button>
                          <Divider type="vertical" />
                          <Button
                            type="text"
                            onClick={() => openModal("Edit", record, index)}
                          >
                            Edit
                          </Button>
                          <Divider type="vertical" />
                          <Button
                            type="text"
                            danger
                            onClick={() => handleDeleteLocation(index)}
                          >
                            Delete
                          </Button>
                        </Flex>
                      );
                    },
                  },
                ]}
                dataSource={locations}
                pagination={{
                  pageSize: 50,
                  total: locations?.length,
                  current,
                  onChange: (page) => setCurrent(page),
                }}
              />
            </Card>
          </Col>

          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Search Engine Listing">
              <Form.Item
                label="Meta Title"
                name="meta_title"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Meta Description"
                name="meta_description"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Feature Image"
                name="feature_image"
                {...UploadUtils.formItemProps}
                wrapperCol={{ span: 24 }}
              >
                <Upload {...UploadUtils.buttonPreviewProps}>
                  <UploadUtils.CardContent />
                </Upload>
              </Form.Item>
            </Card>
          </Col>

          <Col span={24}>
            <Flex justify="end" style={{ padding: 24 }} gap={16}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Update Page
                </Button>
              </Form.Item>
            </Flex>
          </Col>
        </Row>
      </Form>
      <Modal
        title={<>{modalMode} Location</>}
        open={isModalOpen}
        onClose={closeModal}
        onCancel={closeModal}
        footer={null}
        width={700}
      >
        <div style={{ marginTop: 18 }}>
          {modalMode === "View" && (
            <Descriptions
              items={[
                {
                  label: <b>Title</b>,
                  children: (
                    <b>{modalData?.location_title || "Not Available"}</b>
                  ),
                  span: 2,
                },
                {
                  label: <b>Phone Number</b>,
                  children: <b>{modalData?.phone_number || "Not Available"}</b>,
                  span: 1,
                },
                {
                  label: <b>Email</b>,
                  children: <b>{modalData?.email || "Not Available"}</b>,
                  span: 1,
                },
                {
                  label: <b>Website URL</b>,
                  children: <b>{modalData?.website_url || "Not Available"}</b>,
                  span: 2,
                },
                {
                  label: <b>Address</b>,
                  children: (
                    <b>{modalData?.location_address || "Not Available"}</b>
                  ),
                  span: 2,
                },
              ]}
              column={2}
              bordered
            />
          )}
          {(modalMode === "Add" || modalMode === "Edit") && (
            <Form
              layout="vertical"
              initialValues={modalData}
              onFinish={handleFormSubmit}
            >
              <Form.Item
                label="Location Title"
                name="location_title"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: "Status is required",
                  },
                ]}
                initialValue="ACTIVE"
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  options={[
                    {
                      value: "ACTIVE",
                      label: "Active",
                    },
                    {
                      value: "INACTIVE",
                      label: "Inactive",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Location Address"
                name="location_address"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phone_number"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Website URL"
                name="website_url"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                  {
                    // type: "url",
                    // warningOnly: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Latitude"
                    name="lat"
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lng"
                    label="Longitude"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Flex align="center" justify="end" gap={16}>
                <Button onClick={closeModal}>Cancel</Button>
                <Form.Item noStyle>
                  <Button type="primary" htmlType="submit">
                    {modalMode === "Add" ? "Add Location" : "Update Location"}
                  </Button>
                </Form.Item>
              </Flex>
            </Form>
          )}
        </div>
      </Modal>
    </>
  );
};

export default VerifiedInstallerForm;
