import React from "react";
import { Form, Upload } from "antd";
import UploadUtils from "../../utilities/uploadUtils";
import SortDraggableList from "../SortDraggableList";

const AboutBannerSort = ({ data, setData, form }) => {
  const bannerItems =
    data?.banner3_images?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const renderBannerContent = (field) => (
    <Form.Item
      label="Image"
      name={[field.name, "image"]}
      {...UploadUtils.formItemProps}
      wrapperCol={{ span: 24 }}
    >
      <Upload {...UploadUtils.buttonPreviewProps}>
        <UploadUtils.CardContent />
      </Upload>
    </Form.Item>
  );

  return (
    <SortDraggableList
      items={bannerItems}
      renderContent={renderBannerContent}
      setData={setData}
      form={form}
      formField="banner3_images"
      addContent={true}
      showRemoveOption={true}
      cardTitle="Banner Image"
    />
  );
};

export default AboutBannerSort;
