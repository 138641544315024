import React from "react";
import { Form, Upload } from "antd";
import UploadUtils from "../../utilities/uploadUtils";
import SortDraggableList from "../SortDraggableList";
import ReactQuill from "react-quill";

const AboutLocationSort = ({ data, setData, form }) => {
  const locationItems =
    data?.about_location?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const renderAboutLocationContent = (field) => (
    <div style={{ display: "flex", gap: "20px", width: "280px" }}>
      <div>
        <Form.Item
          label="Location Address"
          name={[field.name, "location_address"]}
          wrapperCol={{ span: 24 }}
        >
          <ReactQuill />
        </Form.Item>

        <Form.Item
          label="Location Image"
          name={[field.name, "location_image"]}
          {...UploadUtils.formItemProps}
          wrapperCol={{ span: 24 }}
        >
          <Upload {...UploadUtils.buttonPreviewProps}>
            <UploadUtils.CardContent />
          </Upload>
        </Form.Item>
      </div>
    </div>
  );

  return (
    <SortDraggableList
      items={locationItems}
      renderContent={renderAboutLocationContent}
      setData={setData}
      form={form}
      formField="about_location"
      addContent={true}
      showRemoveOption={true}
      cardTitle="Location"
    />
  );
};

export default AboutLocationSort;
